@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');


@font-face {
  font-family: "Avenir Pulse";
  src:  url("/assets/fonts/AvenirNextW01/AvenirNextLTW01-Regular.woff2") format('woff2'),
        url("/assets/fonts/AvenirNextW01/AvenirNextLTW01-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Avenir Pulse";
  src:  url("/assets/fonts/AvenirNextW01/AvenirNextLTW01-Demi.woff2") format('woff2'),
        url("/assets/fonts/AvenirNextW01/AvenirNextLTW01-Demi.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
}


$family-avenir: 'Avenir Pulse', "Lato", 'Nunito Sans', sans-serif;
$family-nunito: 'Nunito Sans', sans-serif;
$weight-bold: 700;
$black: #030303;
$dark-blue: #13023f;
$dark-blue-75: #4e426f;
$dark-blue-15: #D9D6E0;
$pink: #ff4070;
$pink-110: #e53964;
$light-pink: #ffecf1;
$black-transparent: rgba(0, 0, 0, 0.12);
$grey: #9a9a9a;
$grey-100: #e5e5e5;
$grey-75: #b4b4b4;
$grey-50: #cccccc;
$grey-25: #686868;
$grey-dividers: rgba(3, 3, 3, 0.08);
$grey-flat: #e8e8e8;
$grey-border: #979797;
$beige: #f6f4f4;
$beige-110: #dcdbdb;
$beige-inside: #e7e7e7;
$dark: #2f2f2f;
$green-125: #0daf9a;

// overrides
$text: $black;
$body-background-color: $beige;
$family-primary: $family-avenir;
$body-color: $black;
$card-color: $black;
$link: $black;
$link-hover: $black;
$primary: $pink;
$button-border-width: 1.5px;
$button-border-color: $grey;
$button-focus-box-shadow-size: 0;
$button-background-color: transparent;
$menu-item-active-background-color: $grey-flat;
$menu-item-active-color: $black;
$menu-item-radius: 8px;
$input-height: 3rem; // does not work :(
$control-radius: 8px;
$control-padding-vertical: 1rem;
$control-padding-horizontal: 1rem;
$input-radius: 8px;
$input-focus-box-shadow-size: none;
$input-focus-box-shadow-color: none;
$input-shadow: none;
$input-border-color: $beige-110;
$input-focus-border-color: $dark-blue;
$input-color: $black;
$input-placeholder-color: $grey;
$level-item-spacing: 1rem;
$tablet: 940px;
$mobile-media: "only screen and (max-width : #{$tablet})";
$navbar-item-hover-background-color: white;
$navbar-height: 4.5rem;
$navbar-dropdown-boxed-shadow: 0 2px 8px 0 $black-transparent;
$navbar-dropdown-boxed-radius: $menu-item-radius;
$navbar-item-img-max-height: 100%;
$navbar-dropdown-border-top: 0px;
$navbar-breakpoint: $tablet;
$dropdown-content-padding-bottom: 0;
$dropdown-content-padding-top: 0;
$dropdown-content-radius: 8px;
$link-hover: $pink-110;
$box-color: black;
$tabs-link-padding: 0.75rem 0;
$tabs-border-bottom-width: 1px;
$tabs-border-bottom-color: $grey-100;
$tag-background-color: transparent;

$container-tablet-padding: 2.5rem;
$container-max-width: 65rem;

$profile-edit-diameter: 80px;

@mixin cut-text($maxWidth) {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: $maxWidth;
  white-space: nowrap;
}
