/* You can add global styles to this file, and also import other style files */
@charset "utf-8";
@import "theme/variables";
@import "~bulma/bulma.sass";
@import "~bulma-calendar/src/sass/index.sass";
@import '~@angular/cdk/overlay-prebuilt.css';
@import "theme/custom";
// todo: move to more module
@import '~ngx-toastr/toastr';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}
