@import "variables";

// To anable quill etitor on ionic
[contenteditable] {
  -webkit-user-select: auto;
  user-select: text;
}

.button {
  height: 2rem;
}

.button.is-large {
  height: 2.5rem;
  font-size: 1.125rem; //Jei matysi, kad sitas stilius naudojams daugiau, sukursiu atkira typography stiliu veliau
  //font-weight: $weight-bold;
}

.button:not(.is-flat, .is-semi-rounded) {
  // min-width: 5.75rem;
  line-height: 0;
}

.icon-button {
  background-color: $beige;
  padding: 0.5rem;
  border-radius: 0.5rem;

}

.icon-background {
  background-color: $beige;
  border-radius: 0.5rem;
  &:active {
    background-color: $beige-110;
  }
}

.button.is-rounded {
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &.has-no-padding {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
}

.button.is-primary {
  &.is-hovered,
  &:hover, &:active {
    background-color: $pink-110;
  }

  &[disabled] {
    background-color: $grey-50;
  }
}

.button.is-flat {
  border: none;
  outline: none;
  background-color: white;

  &.is-hovered,
  &:hover, &:active {
    background-color: $grey-flat;
  }

  &[disabled] {
    background-color: $grey-50;
  }
}

.button.is-secondary {
  background-color: $dark-blue;
  background-position: center;
  transition: background 0.6s ease-in;
  color: white;
  border: none;
  padding-right: 2.5rem;
  padding-left: 2.5rem;

  &.is-hovered,
  &:hover, &:active {
    // background-color: $dark-blue-75;
    color: white;
  }

  &:hover {
    background: $dark-blue radial-gradient(circle, transparent 2%, $dark-blue-75 1%) center/15000%;
  }

  &:active {
    background-color: $dark-blue-75;
    background-size: 100%;
    transition: background 0s;
  }

  &[disabled] {
    background-color: $grey-50;
  }
}

.button.is-secondary-grey {
  background-color: $beige;
  color:$black;
  border: none;

  &.is-hovered,
  &:hover, &.active {
    background-color: $beige-110;
    color: $black;
  }

  &[disabled] {
    background-color: $beige;
    color: $grey;
  }
}

.button.icon-only {
  font-size: 1rem;
}

.button.is-outlined {

  padding-right: 3.125rem;
  padding-left: 3.125rem;

  &.is-hovered,
  &:hover,
  &:active {
    border-color: $primary;
  }
}

.button.is-primary-outlined {
  border-color: $primary;
  color: $primary;
  &.is-hovered,
  &:hover,
  &.active {
    color: white;
    border-color: $primary;
    background-color: $primary;
  }
}

.button.is-secondary-outlined {
  border-color: $dark-blue;
  color: $dark-blue;

  &.is-hovered,
  &:hover,
  &.active {
    background-color: $dark-blue;
    border-color: $dark-blue;
    color: white;
  }
}

.grey-button {
  background-color: $beige;
  border-radius: 0.5rem;
  flex-shrink: 0;
  border: none;
  gap: 0.5rem;
  padding: 1rem;
  height: 3rem;
}

.card {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 0.25rem 2rem 0 $black-transparent;
}

.card-shadow {
  box-shadow: 0 0.25rem 0.75rem 0 $black-transparent;
  margin-bottom: 1rem;
}

.card-image {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow: hidden;

  &.has-no-border-radius {
    border-radius: 0;
  }
}

.card-content {
  padding: 1rem;
}

.page-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.input,
.textarea,
.button.dropdown,
.datetimepicker-dummy-wrapper {
  caret-color: $pink;
  height: $input-height;
}

::-moz-placeholder {
  opacity: 1 !important;
  font-weight: bold;
}

::-webkit-input-placeholder {
  font-weight: bold;
}

::-ms-input-placeholder {
  font-weight: bold;
}

.datetimepicker-dummy-wrapper {
  border-radius: 0.5rem;
  height: $input-height !important;
  padding: 0.8rem 1rem;
  box-shadow: none !important;
  background-color: white;

  &::-webkit-input-placeholder {
    font-weight: bold;
  }

  &:-moz-placeholder {
    font-weight: bold;
  }

  &::-moz-placeholder {
    font-weight: bold;
  }

  &:-ms-input-placeholder {
    font-weight: bold;
  }
}

.datetimepicker-dummy {
  height: $input-height;
}

.datetimepicker-dummy:before, .datetimepicker-dummy::before {
  right: 0.75rem !important;
  top: 50% !important;
  transform: translateY(-50%);
  left: auto;
  content: "";
  width: 1.5rem;
  height: 1.5rem;
}

.datepicker {
  .datetimepicker-dummy:before, .datetimepicker-dummy::before {
    mask: url('~assets/icons/calendar-normal.svg') !important;
    mask-size: contain !important;
    mask-repeat: no-repeat !important;
  }
}

.timepicker {
  .datetimepicker-dummy:before, .datetimepicker-dummy::before {
    mask: url('~assets/icons/timer.svg') !important;
  }
}

.datetimepicker-dummy.is-primary:before, .datetimepicker-dummy.is-primary::before {
  background-color: black;
}

.datetimepicker-dummy .datetimepicker-dummy-wrapper .datetimepicker-dummy-input {
  text-align: left;
  max-width: 100%;
}

.datetimepicker-dummy .datetimepicker-dummy-wrapper .datetimepicker-dummy-input:first-child {
  text-indent: 0;
}

.datetimepicker-clear-button {
  display: none;
}

.datetimepicker {
  min-width: 247px;
}

.button.dropdown {
  border-color: $beige-110;
  border-width: 1px;
  overflow: hidden;
}

.control:not(.is-fullwidth) {
  max-width: 28.75rem;
}

button[aria-controls="dropdown-menu"]:not(.narrow), .dropdown-menu:not(.narrow), a[aria-controls="dropdown-menu"]:not(.narrow) {
  min-width: 16rem;
  width: 100%;
  height: 3rem;
  justify-content: space-between;
}

button[aria-controls="dropdown-menu"].narrow, .dropdown-menu.narrow, a[aria-controls="dropdown-menu"] {
  min-width: 7.5rem;
  justify-content: space-between;
}

.dropdown-menu {
  min-width: 20.75rem;
  width: 100%;
  padding: 0;
  margin-top: 0.5rem;
  height: 3rem;
  justify-content: space-between;
}

.dropdown-content {
  box-shadow: 0 0.125rem 0.5rem 0 $black-transparent;
  border: solid 1px $grey-100;
}

.image.is-2by1 {
  padding-top: 45%;
}

* {
  &:focus {
    outline: none;
  }
}

.font-color-action {
  color: $pink;
  &:active {
    color: $pink-110;
  }
}

.has-background-beige {
  background-color: $beige;
}

.has-background-white {
  background-color: white;
}

.has-background-dark-blue {
  background-color: $dark-blue;
  color: white;
}

/*
TYPOGRAPHY
Guides: https://zpl.io/2j9y9Rm
*/

.text-header-1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.page-title-1 {
  font-size: 2.375rem;
  font-weight: 700;
  line-height: 3.25rem;
}

.text-header-2, .page-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.text-content-body {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-subheading-1 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
}

.text-body-1,
.label,
.input {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.label-2 {
  display: inline-flex;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375;
  margin-bottom: 0.5rem;
}

.text-body-2,
.button {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.text-body-small{
  font-size: 0.875rem;
}

.text-content-body {
  font-size: 1rem;
  line-height: 1.75rem;
}

.has-text-weight-bold {
  font-weight: 700;
}

.login-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .column {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}


/* OLD TYPOGRAPHY

Need to delete later */

.text-info-sm {
  font-size: 0.75rem
}

.text-info {
  font-size: 0.875rem;
}


.text-regular {
  font-size: 1rem
}

.text-description {
  font-size: 1.125rem
}

.text-list-item {
  font-size: 1.25rem
}

.text-subtitle {
  font-size: 1.375rem;
}

.text-title {
  font-size: 1.75rem;
}

.text-heading {
  font-size: 2rem;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.has-margin-bottom-xlg {
  margin-bottom: 2.5rem !important;
}

.has-margin-bottom-lg {
  margin-bottom: 2rem !important;
}

.has-margin-bottom-mlg {
  margin-bottom: 1.5rem !important;
}

.has-margin-bottom-md {
  margin-bottom: 1rem !important;
}

.has-margin-bottom-sm {
  margin-bottom: 0.5rem !important;
}

.has-margin-bottom-xs {
  margin-bottom: 0.25rem !important;
}

.has-margin-right-md {
  margin-right: 1rem !important;
}

.has-margin-right-mlg {
  margin-right: 1.5rem !important;
}

.has-margin-right-lg {
  margin-right: 2rem;
}

.has-margin-right-sm {
  margin-right: 0.5rem !important;
}

.has-margin-right-xs {
  margin-right: 0.25rem !important;
}

.has-margin-left-sm {
  margin-left: 0.5rem;
}

.has-margin-left-md {
  margin-left: 1rem;
}

.has-margin-top-xs {
  margin-top: 0.25rem;
}

.has-margin-top-sm {
  margin-top: 0.5rem;
}

.has-margin-top-md {
  margin-top: 1rem;
}

.has-margin-top-mlg {
  margin-top: 1.5rem;
}

.has-margin-top-lg {
  margin-top: 2rem;
}

.has-margin-top-xlg {
  margin-top: 2.5rem;
}

.has-margin-sm {
  margin: 0.5rem;
}

.has-margin-md {
  margin: 1rem;
}

.has-padding-md {
  padding: 1rem;
}

.has-padding-mlg {
  padding: 1.5rem;
}

.has-padding-lg {
  padding: 2rem;
}

.has-paddings-xsm {
  padding: 0.25rem;
}

.has-padding-bottom-sm {
  padding-bottom: 0.5rem !important;
}

.has-padding-bottom-md {
  padding-bottom: 1rem;
}

.has-padding-bottom-mlg {
  padding-bottom: 1.5rem;
}

.has-padding-right-xsm {
  padding-right: 0.25rem;
}

.has-padding-right-sm {
  padding-right: 0.5rem;
}

.has-padding-right-md {
  padding-right: 1rem;
}

.has-padding-right-mlg {
  padding-right: 1.5rem;
}

.has-padding-left-sm {
  padding-left: 0.5rem;
}

.has-padding-left-md {
  padding-left: 1rem;
}

.has-padding-left-mlg {
  padding-left: 1.5rem;
}

.has-text-white {
  color: white;
}

.has-text-grey {
  color: $grey-25;
}

.has-text-pink {
  color: $pink;
}

.is-clickable {
  cursor: pointer;
  user-select: none;
}

.is-non-clickable {
  cursor: default!important;
}

.is-80x80, .is-80x80 img {
  width: 5rem !important;
  height: 5rem !important;
}

.is-88x88, .is-88x88 img {
  width: 5.5rem !important;
  height: 5.5rem !important;
}

.is-rounded {
  border-radius: 290486px;
}

.is-semi-round {
  border-radius: 0.5rem;
}

.has-overflow-hidden {
  overflow: hidden;
}

.corner-element {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  // z-index: 3 !important;
}

.back-element {
  position: relative;
  z-index: 1;
}

.control.has-icons-right .icon, .control.has-icons-left .icon {
  justify-content: flex-start;
  top: none;
  height: 100%;
  max-height: 56px;
}

.control {
  &.has-icons-both .is-left, &.has-icons-both .is-right {
    position: absolute;
    z-index: 1;
  }

  &.has-icons-both .is-left {
    top: 0.7rem;
    left: 0.5rem;
  }

  &.has-icons-both .is-right {
    top: 1rem;
    right: 0.8rem;
  }
}

.control.has-icons-left .icon {
  left: 0.5rem;
}

.control.has-icons-right .icon {
  right: 0.5rem;
}

.dropdown-content {
  .dropdown-item {
    margin-bottom: 0;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    &:last-of-type {
      border-bottom-left-radius: $dropdown-content-radius;
      border-bottom-right-radius: $dropdown-content-radius;
    }

    &:first-of-type {
      border-top-left-radius: $dropdown-content-radius;
      border-top-right-radius: $dropdown-content-radius;
    }
  }
}

.dialog-backdrop {
  background: rgba(3, 3, 3, 0.4);
}

.box {
  display: flex;
  justify-content: center;
  padding: 0;
}

a, p, span, h1, h2 {
  word-break: break-word;
}

agm-map {
  height: 28rem;

  &.small {
    height: 18rem;
  }
}

.marker-label {
  background-color: $dark-blue;
  border: solid 2px white;
  border-radius: 1.5rem;
  color: white;
  padding: 0rem 0.375rem;
  max-width: 6rem;
  white-space: nowrap;      /*keep text on one line */
  overflow: hidden;         /*prevent text from being shown outside the border */
  text-overflow: ellipsis;  /*cut off text with an ellipsis*/
  display: inline-block;
  font-weight: bold;
}

.is-16x16 {
  height: 1rem;
  width: 1rem;
}

.is-20x20 {
  width: 1.25rem;
  height: 1.25rem;
}

.is-32x32 {
  width: 2rem;
  height: 2rem;
}

.is-36x36 {
  width: 2.25rem;
  height: 2.25rem;
}

.is-40x40 {
  width: 40px;
  height: 40px;
}

.image-notification {
  display: inline-block;
  position: relative;

  .notifications-number {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
  }
}

.field .label {
  display: inline-block;
  white-space: nowrap;
}

.block {
  display: block;
}

.footer, .navbar, .hero-body {
  > .container {
    max-width: $container-max-width;
    padding-right: $container-tablet-padding;
    padding-left: $container-tablet-padding;
  }
}

.container.main {
  max-width: $container-max-width;
  padding-right: $container-tablet-padding;
  padding-left: $container-tablet-padding;
}

progress.progress {
  height: 24px;
  border-radius: 8px;
}

.cut-text {
  @include cut-text(11.5rem);
}

.dropdown-content {
  max-height: 20em;
  overflow: auto;
}

.tag {
  padding: 0.2rem;
  border: 1px solid #E6E6E6;
  height: auto !important;

  &.primary {
    border: 2px solid $primary;
    color: $primary !important;
  }

  &.big {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
  }

  &.grey {
    border: 2px solid $grey;
    color: $black !important;
  }

  &.grey:not(.black-font) {
    color: $grey !important;
  }
}

.list-tab {
  min-height: 3.5rem;
  border-top: 1px solid $grey-100;
  padding: 0 1rem 0 1rem;
}

:not(.ql-container) > .ql-editor {
  padding: 0 0 1rem 0;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .ql-editor {
    min-height: 200px;
    font-family: "Avenir Pulse", "Lato", "Nunito Sans", sans-serif;
    font-size: 0.875rem;
    caret-color: $pink;

    &.ql-blank::before {
      color: rgb(168, 156, 154);
      font-weight: bold;
      font-style: normal;
    }
  }
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.has-border-top {
  border-top: 1px solid $grey-100;
}

.no-border-radius {
  border-radius: 0rem;
}

.is-high-button {
  height: 2.5rem;
}

.survey-fill-dialog {
  .card {
    border-radius: 0 !important;
  }
}

ion-toggle {
  --background-checked:#ff4070;
}

.is-flex-direction-column {
  display:flex;
  flex-direction: column;
}
